exports.components = {
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-pages-404-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/pages/404.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-pages-404-js" */),
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-pages-about-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/pages/about.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-pages-about-js" */),
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-pages-data-archive-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/pages/data-archive.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-pages-data-archive-js" */),
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-pages-index-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/pages/index.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-pages-index-js" */),
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-pages-investigations-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/pages/investigations.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-pages-investigations-js" */),
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-post-template-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/templates/post-template.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-post-template-js" */),
  "component---src-pages-datasets-chemical-js": () => import("./../../../src/pages/datasets/chemical.js" /* webpackChunkName: "component---src-pages-datasets-chemical-js" */),
  "component---src-pages-datasets-incident-js": () => import("./../../../src/pages/datasets/incident.js" /* webpackChunkName: "component---src-pages-datasets-incident-js" */),
  "component---src-pages-lost-found-archive-js": () => import("./../../../src/pages/lost-found-archive.js" /* webpackChunkName: "component---src-pages-lost-found-archive-js" */),
  "component---src-pages-lost-found-js": () => import("./../../../src/pages/lost-found.js" /* webpackChunkName: "component---src-pages-lost-found-js" */),
  "component---src-pages-memory-js": () => import("./../../../src/pages/memory.js" /* webpackChunkName: "component---src-pages-memory-js" */),
  "component---src-templates-incident-template-js": () => import("./../../../src/templates/incident-template.js" /* webpackChunkName: "component---src-templates-incident-template-js" */),
  "component---src-templates-unit-template-js": () => import("./../../../src/templates/unit-template.js" /* webpackChunkName: "component---src-templates-unit-template-js" */)
}

