import React, { useContext, useEffect, useState } from "react";
import useDataApi from "hooks/useDataApi";
import Timeline from "components/charts/timeline";
import { LocaleContext } from "context/locale-context";
import { ThemeContext } from "context/theme-context";
import useWindowSize from "hooks/useWindowSize";

function TimelineData({ height, lang, width }) {
  const locale = useContext(LocaleContext);
  const { apiUrl } = useContext(ThemeContext);
  const [isMobile, setIsMobile] = useState(false);
  const [windowWidthSize] = useWindowSize();
  const [{ data, isLoading }] = useDataApi(
    `${apiUrl}/collections/?collection=chemical&lang=${locale}`,
    []
  );
  const flexibleWidth = width ? width : isMobile ? 300 : 800;
  useEffect(() => {
    windowWidthSize < 992 ? setIsMobile(true) : setIsMobile(false);
  }, [windowWidthSize]);

  return (
    <Timeline
      lang={lang}
      incidents={data.data}
      height={height}
      width={flexibleWidth}
      isLoading={isLoading}
    />
  );
}

export default TimelineData;
